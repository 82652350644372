import React from "react";
import {ContentDiv, StyledImg, Wrapper} from "./PictureTextSection.styles";


const PictureTextSection = ({picture_text_section_data, children, style}) => {
    return(
        <Wrapper style={style}>
            <StyledImg
                image={picture_text_section_data.img.childImageSharp.gatsbyImageData}
                alt={picture_text_section_data.alt}
            />
            <ContentDiv>
                    {children}
            </ContentDiv>
        </Wrapper>
    )
}

export default PictureTextSection;