import React from "react";
import {StyledLink, Wrapper} from './OutlinedButton.styles';


const OutlinedButton = ({text, slug}) => {
    return (

            <StyledLink to={slug}>
                <Wrapper>
                    {text}
                </Wrapper>
            </StyledLink>

    )
}

export default OutlinedButton;