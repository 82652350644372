import * as React from "react";
import {useState} from "react";
import ProjectChooser from "./ProjectChooser/ProjectChooser";
import {ContentDiv, ContentItem, ProjectUl, RoundButton, Wrapper, ButtonWrapper} from "./ProjectSection/ProjectSection.styles";
import OutlinedFlexBox from "../BOXES/OutlinedFlexBox/OutlinedFlexBox";
import LeftIcon from "../../images/icon_left.svg"
import RightIcon from "../../images/icon_right.svg"
import StyledLink from "../BASICS/StyledLink/StyledLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function modulo(n, m) {
    return ((n % m) + m) % m;
}

const ProjectArea = ({projectData}) => {

    //Current Project
    const [currentProject, setCurrentProject] = useState(projectData[0]);
    const handleCurrentProject = (currentProject) => {
        setCurrentProject(currentProject);
        setCurrentPicIndex(0);
    }

    //Current Picture
    const [currentPicIndex, setCurrentPicIndex] = useState(0);
    const handleCurrentPicIndexForwards = () => setCurrentPicIndex(prevState => modulo((prevState + 1), data.pics.length))
    const handleCurrentPicIndexBackwards = () => setCurrentPicIndex(prevState => modulo((prevState - 1), data.pics.length))

    //Zoom
    const [zoomIn, setZoomIn] = useState(false);
    const handleZoomIn = () => { setZoomIn(prevState => !prevState)}

    //Data in correct language
    const data = currentProject.frontmatter.de;
    const image = getImage(data.pics[currentPicIndex].src)

    return(
        <>
            <h1>Projekte</h1>

            <ProjectChooser projectData={projectData} callback={handleCurrentProject}/>

            {/*ProjectSection*/}
            <Wrapper className="fullwidth_correction">
                <ContentDiv>
                    <ContentItem key={1}>
                        <ButtonWrapper>
                            <RoundButton
                                onClick={handleCurrentPicIndexBackwards}
                                onKeyDown={handleCurrentPicIndexBackwards}
                            >
                                <img src={LeftIcon} alt="nach links"/>
                            </RoundButton>
                            <RoundButton
                                onClick={handleCurrentPicIndexForwards}
                                onKeyDown={handleCurrentPicIndexForwards}
                            >
                                <img src={RightIcon} alt="nach rechts"/>
                            </RoundButton>
                        </ButtonWrapper>


                        <div
                            onClick = {() => handleZoomIn()}
                            onKeyDown={() => handleZoomIn()}
                        >
                            <GatsbyImage
                                image={image}
                                objectFit={'contain'}
                                alt={data.pics[currentPicIndex].description}
                                className={zoomIn? "zoomIn" : "zoomOut"}
                                key={data.pics[currentPicIndex].src}
                                layout="constrained"
                                loading="eager"
                            />
                        </div>
                    </ContentItem>

                    {/*Content*/}
                    <ContentItem key={2}>
                        <h3>Übersicht</h3>
                        <OutlinedFlexBox className="overviewDiv">
                            <div>
                                <h4>Technologien</h4>
                                <ProjectUl>
                                    {data.technologies.map(item =>
                                        <li key={item}>{item}</li>
                                    )}
                                </ProjectUl>
                            </div>
                            <div>
                                <h4>Beteiligte</h4>
                                <ProjectUl>
                                    {data.contributors.map(item =>
                                        <li key={item}>{item}</li>
                                    )}
                                </ProjectUl>
                            </div>

                            {data.links.length > 0 &&
                                <div className="linksSection">
                                    <h4>Links</h4>
                                    <ProjectUl>
                                        {data.links.map(item =>
                                            <li key={item.text}>
                                                <StyledLink href={item.href}>{item.text}</StyledLink>
                                            </li>
                                        )}
                                    </ProjectUl>
                                </div>
                            }


                        </OutlinedFlexBox>
                    </ContentItem>

                    <ContentItem key={3}>
                        <h3>Beschreibung</h3>
                        <div className="descriptionContent" dangerouslySetInnerHTML={{ __html: data.descriptionHTML}}></div>
                    </ContentItem>

                </ContentDiv>

            </Wrapper>
        </>
    )

}

export default ProjectArea;
