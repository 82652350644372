import SEO from "../components/SEO/SEO";
import * as React from "react";
import PageBody from "../components/PageBody/PageBody";
import {useItPageQuery} from "../hooks/useItPageQuery";
import PictureTextSection from "../components/PictureTextSection/PictureTextSection";
import OutlinedButton from "../components/BASICS/OutlinedButton/OutlinedButton";
import styled from "styled-components";
import {useProjectsQuery} from "../hooks/useProjectsQuery";
import ProjectArea from "../components/Projects/ProjectArea";
import StyledLink from "../components/BASICS/StyledLink/StyledLink";

export const ContactCard = styled.div` 

    border: 1px solid var(--THEME_PRIMARY1);
    border-radius: 4px;
    padding: 15px 15px 25px;
    margin-bottom: var(--vertical_space);
    color: var(--THEME_80);
    
    .projects img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
`

export const EducationTextSection = styled.div`

    max-height: 350px;
    
    >div{
        height: 100%;
        width: 100%
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    
    div>div{
        text-align: left;
        padding: 0;
        margin: auto;
    }
    h1, p{
        margin: auto;
        margin-bottom: 20px;
    }
    p{
        line-height: 1.45em;
    }
    a{
        display: inline-block;
        line-height: 1.45em;
        
        :visited {
            color: var(--THEME_60);
        }
    }
    
    @media (max-width: 870px){
        >div{
            padding: 10px;
        }
    }
`


const ITPage = () => {

    const { mdx: {frontmatter : itPageData}} = useItPageQuery();
    const { allMdx: {nodes : projectData}} = useProjectsQuery();


    return(
        <PageBody pageData={itPageData}>
            <PictureTextSection picture_text_section_data={itPageData.pictureTextSection}>
                <EducationTextSection>
                    <div>
                        <h1>Ausbildung</h1>
                        <div>
                            <p>Bachelor of Science in Informatik mit Vertiefungsrichtung in Design und Management.</p>
                            <p>(Fachhochschule Nordwestschweiz, Brugg-Windisch)</p>

                            <StyledLink
                                href="https://www.linkedin.com/posts/louisa-r-2b07a9185_vielen-dank-an-alle-dozierenden-freunde-activity-6983357955572129792-7cHV?utm_source=share&utm_medium=member_desktop"
                            >
                                LinkedIn
                            </StyledLink>
                        </div>
                    </div>
                </EducationTextSection>
            </PictureTextSection>

            <ProjectArea projectData={projectData}/>

            <ContactCard>
                <h1>Kontakt aufnehmen</h1>
                <p>Ich freue mich, Sie kennenzulernen.</p>
                <OutlinedButton text="Kontakt" slug="/contact"/>
            </ContactCard>
        </PageBody>
    );
}

export const Head = () => <SEO title="IT" />

export default ITPage