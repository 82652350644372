import * as React from "react";
import {ProjectChooserDiv} from "./ProjectChooser.styles";
import {useState} from "react";

const ProjectChooser = ({ projectData, callback }) => {

    const [openProject, setOpenProject] = useState(projectData[0]);

    return(
        <ProjectChooserDiv>
            <ul>
                {projectData.map(item =>{
                    const i = item.frontmatter.de;

                    return(
                        <li
                            key={i.short_title}
                            onClick = {() => {
                                callback(item);
                                setOpenProject(item);
                            }}
                            onKeyDown={() => {
                                callback(item);
                                setOpenProject(item);
                            }}
                            role="button"
                            tabIndex="0"
                            className={openProject === item ? "active" : "inactive"}
                        >
                            <h2>{i.short_title}</h2>
                        </li>
                    )
                })}
            </ul>
        </ProjectChooserDiv>
    )

}

export default ProjectChooser;