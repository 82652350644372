import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

export const Wrapper = styled.div`
    width: calc(100% - 2px);
    position: relative;
    overflow: hidden;
    margin-bottom: var(--vertical_space);
    padding: 0;
    background-color: var(--THEME_00);
    
    @media only screen and (max-width: 500px) {
        padding: 0 10px 10px;
      }
    
    animation: 2s ease-out 0s 1 slideInFromBottom;
    @keyframes slideInFromBottom {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    
    width: 100vw;
    
    .overviewDiv{
        div{
            padding: 0;
        };
        border: none;
    }
    .zoomOut{
        max-height: 20vh;
        cursor: zoom-in;
    }
    .zoomIn{
        max-height: none;
        width: 100%;
        cursor: zoom-out;
    }
`;

export const ContentDiv = styled.div`
    margin: auto;
    
    margin: var(--vertical_space) var(--lateral_distance);
  
      @media only screen and (min-width: 1250px) {
        max-width: 1000px;
        margin: var(--vertical_space) auto;
      }
      
      @media only screen and (max-width: 500px) {
        margin: var(--vertical_space) 0;
      }
`;

export const ContentItem = styled.div`
    max-width: 1000px;
    margin: 10px auto;
    
    :first-of-type{
        margin: 0 auto 10px auto;
    }
    
    h4 {
        // margin: 0 auto 0.5rem;
        margin: 0 auto 8px;
    }
    @media screen and (min-width: 500px) and (max-width: 767px) {
        h4 {
            margin: 0 auto 0.5rem;
        }
    }
    
    .descriptionContent{
        text-align: left;
        display: inline-block;
        
        ul{
            padding: 0 0 0 16px;
        }
        li{
            margin-bottom: 0;
        }
        a{
            color: var(--THEME_PRIMARY1_STRONG);
            text-decoration: underline;
            :visited{
                color: var(--THEME_PRIMARY1_40);
            }
        } 
       
    }
`


export const StyledImg = styled(GatsbyImage)`
    display: inline-block;
    transition: all 1s ease;
    img{
        object-fit: contain !important;
    }
    div{
        background-color: transparent !important;
        text-align: center;
    }
`

export const ProjectUl = styled.ul`
    display: inline-grid;
    margin-bottom: 0;
    margin-left: 0;
    
    li{
        text-align: left;
        margin-bottom: 0;
    }
`

export const ButtonWrapper = styled.div`
    display: inline-flex;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--THEME_40);
    padding-bottom: 8px;
`

export const RoundButton = styled.div`
    border-radius: 50%;
    background-color: var(--THEME_40);
    width: 32px;
    height: 32px;
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
    display: inline-block;
    
    transition: all 0.5s ease;
    :hover{
        background-color: var(--THEME_80);
    }
    :active{
        background-color: var(--THEME_BLACK);
    }
    
    img{
        margin: 0;
        padding: 0;
        max-width: 100%;
        max-height: 100%;
    }
    
    :nth-child(1){
        padding: 6px 10px 6px 6px;
    }
    :nth-child(2){
        padding: 6px 6px 6px 10px;
    }
`


