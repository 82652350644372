import styled from "styled-components";
import {Link} from "gatsby";

export const Wrapper = styled.div`
  font-family: var(--FONT_2);
  border-radius: 4px;
  padding: 10px 32px;
  margin: auto;
  transition: all 0.5s ease;
  
  a div{
    color           : var(--THEME_BLACK);
  }
  
  border            : 1px solid var(--THEME_PRIMARY1_OP-60);
  background-color  : var(--THEME_00);
  
  :hover{
    background-color: var(--THEME_PRIMARY1_OP-60);
    color           : var(--THEME_BLACK);
  } 
`

export const StyledLink = styled(Link)`
    display: inline-block;
`

