import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

export const StyledImg = styled(GatsbyImage)`
  flex: 1;
  
  img{
    object-position: 69% 15%;
  }
  
  max-height: 350px;
  @media only screen and (max-width: 500px){
        max-height: 300px
  }
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    margin-bottom: var(--vertical_space);
    border-radius: 4px;
    
    @media only screen and (max-width: 500px){
        flex-direction: column;
    }
`;

export const ContentDiv = styled.div`
    background-color: var(--THEME_20);
    color: var(--THEME_BLACK);
    flex: 2;
    text-align: center;
    display: inline-flex;
    
    div{
        padding: 20px;
    }
    h1{
        color: var(--THEME_WHITE);
        text-transform: uppercase;
    }
    
    p{
        font-size: 1rem;
        margin: auto;
        font-family: var(--font_text);
        text-align: left;
    }
`;