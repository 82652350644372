import styled from "styled-components";

export const StyledA = styled.a`

    text-decoration: underline;
    color: var(--THEME_PRIMARY1_STRONG);
    
    :link {
        color: var(--THEME_PRIMARY1_STRONG);
    }
    :active,
    :hover {
        color: var(--THEME_PRIMARY1_40);
    }
    
    :visited {
        color: var(--THEME_PRIMARY1);
    }
`