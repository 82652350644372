import React from "react";
import {Wrapper, ContentDiv} from "./OutlinedFlexBox.styles";


const OutlinedFlexBox = ({children, className}) => {
    return(
        <Wrapper className={className}>
            <div>
                <ContentDiv>
                    {children}
                </ContentDiv>
            </div>
        </Wrapper>
    )
}


export default OutlinedFlexBox