import {graphql, useStaticQuery} from "gatsby";


export const useProjectsQuery = () => {
    const data = useStaticQuery(graphql`
    query ProjectsQuery {
      allMdx(
        filter: {frontmatter: {individual_type: {eq: "project-data"}}}
        sort: {fields: frontmatter___position, order: ASC}
      ) {
        nodes {
          frontmatter {
            eng {
              short_title
              title
              technologies
              contributors
              links {
                href
                text
              }
              descriptionHTML
              pics {
                description
                objFit
                src {
                  childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED,
                        blurredOptions: {width: 500}
                    )
                  }
                  publicURL
                }
              }
            }
            de {
              short_title
              title
              technologies
              contributors
              links {
                href
                text
              }
              descriptionHTML
              pics {
                description
                objFit
                src {
                  childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED,
                        blurredOptions: {width: 500}
                    )
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    
    `)

    return data;
}