import styled from "styled-components";

export const ProjectChooserDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    
    li{
        margin: 2px;
        cursor: pointer;
        display: inline-block;
        border-radius: 40px;
        padding: 5px 12px;
        text-align: center;
        transition: all 0.5s ease;
        background-color: var(--THEME_00);
        color: var(--THEME_80);
        
        :hover{
            background-color: var(--THEME_20);
        }
        
        h2{
            font-family: var(--font_link_pics);
            font-size: 1rem;
            font-weight: normal;
            margin-bottom: -2px;
        }
    }
    
    .active{
        background-color: var(--THEME_80);
        color: var(--THEME_00);
        
        :hover{
            background-color: var(--THEME_80);
        }
    }
`