import styled from "styled-components";

export const Wrapper = styled.div`
    width: calc(100% - 2px);
    display: flex;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--THEME_PRIMARY1);
    border-radius: 4px;
    margin: auto auto var(--vertical_space) auto;
    background-color: var(--THEME_WHITE);
    padding: 1rem;
    
    >div{
        margin: auto;
        min-width: 80%;
    }
    
    animation: 2s ease-out 0s 1 slideInFromBottom;
    @keyframes slideInFromBottom {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    
    @media screen and (max-width: 768px) {
        padding: 0;
        text-align: left;
    }
`;

export const ContentDiv = styled.div`    
    display: flex;
    padding: 40px 10px;
    margin: auto;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0 8px 8px 40px;
        
        
        h4{
            margin: 8px 0 0 -28px;
        }
    }
    
    >div{
        flex: 1;
        margin: 0 30px;
        
        @media screen and (max-width: 768px) {
            margin: 0;
        }
    }
    
`;