import {graphql, useStaticQuery} from "gatsby";


export const useItPageQuery = () => {
    const data = useStaticQuery(graphql`
    query ITQuery {
        mdx(frontmatter: {slug: {eq: "/it"}}) {
            frontmatter {
                slug
                title
                image {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                individual_type
                pictureTextSection {
                    img{
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    alt
                    text
                }
            }
        }
    }`)

    return data;
}